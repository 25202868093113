@import 'styles/site-variables';

$brand-primary-color: #e8171f;
$brand-secondary-color: #414d57;
$brand-primary-color-lighter: lighten($brand-primary-color, 10%);

.counselLink-brand-theme {
    // Select input override.
    lsq-select .select-group .lsq-select-input:focus form-control {
        border-color: $blue-highlight;
    }

    // Input overrides.
    .form-group.has-float-label,
    .form-group.has-inner-label,
    .lsq-textarea .form-group {
        .input-group.is-focused {
            border-color: $blue-highlight !important;
        }
    }

    // Button overrides.
    button {
        &.button-primary {
            background: $brand-primary-color !important;
            border: 1px solid $brand-primary-color !important;

            &:hover {
                background: $brand-primary-color-lighter !important;
            }

            &:disabled {
                background: $brand-primary-color-lighter !important;
                color: $white !important;
            }

            &:focus,
            &:active,
            &.selected {
                &:not(:hover) {
                    background-color: $brand-primary-color !important;
                    border-color: $brand-primary-color !important;
                }
            }

            lsq-button-spinner {
                .mat-progress-spinner {
                    circle {
                        stroke: $white !important;
                    }
                }
            }
        }

        &.button-transparent {
            color: $brand-primary-color !important;
            $link-hover-color: $brand-primary-color-lighter;

            &:hover {
                color: $brand-primary-color-lighter !important;
            }
        }
    }

    a:hover {
        color: $black !important;
    }

    // Radio input overrides.
    lsq-radio lsq-icon svg-icon {
        color: $blue-highlight !important;
    }

    .info-section-wrapper {
        background-color: $brand-secondary-color !important;
    }

    lsq-checkbox .checkbox-icon.checked {
        background-color: $brand-primary-color !important;
        border-color: $brand-primary-color !important;
    }
}
