// Hack to prevent scrolling (e.g. when backdrop is active).
.block-scroll {
    overflow: hidden !important;
}

// This removes little X's that IE uses to clear inputs.
input::-ms-clear {
    display: none;
}

// Dragula: disable scrolling when dragging. This is a hack to get around dragula's poor
// support for touch events (i.e. on a phone, dragula can't tell whether you intend to
// scroll or drag).
.gu-unselectable.is-touch-device {
    overflow-y: hidden;
}

ng-scrollbar {
    .ng-scrollbar-view {
        overflow-x: hidden;
        width: 100% !important;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0 !important;
        }
    }

    .ng-scrollbar-thumb {
        background-color: $gray-dark;
        opacity: 0.75;
    }
}

// Fixes the vertical alignment of SVG icons. As of Bootstrap 4.3, the version of "reboot"
// they use sets `svg { vertical-align: middle }`, which messes with our styling.
svg {
    vertical-align: inherit;
}

// Chrome tries to be too helpful and scroll to content that moves (in case an ad pops in).
// However, for us that means after a loading indicator finishes and shows content, the scrolling sometimes moves.
// https://developers.google.com/web/updates/2016/04/scroll-anchoring
body {
    overflow-anchor: none;
}
