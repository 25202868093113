@use "sass:math";

$form-gutter: math.div($grid-gutter-width, 2);

form {
    .lsq-input {
        // Remove margin if the form is using a basic label input.
        &.basic-label-input {
            margin: 0;

            .input-error-message {
                margin-bottom: 1.5rem;
            }
        }
        // only put top/bottom margin on lsq-input when in context of a form.
        margin: 1.5rem 0;
    }

    .col,
    [class*='col-'] {
        padding-right: math.div($form-gutter, 2);
        padding-left: math.div($form-gutter, 2);

        .lsq-input,
        .lsq-textarea {
            // Remove margin if the form is using a basic label input.
            &.basic-label-input {
                margin: 0;
            }
            // only put bottom margin on lsq-input when in context of a form.
            margin: 0rem;
            margin-bottom: 1.5rem;
        }

        .select-group.form-group {
            margin: 0rem;
            margin-bottom: 1.5rem;
        }
    }

    // Checkboxes are structured a little differently.
    lsq-checkbox.col,
    lsq-checkbox[class*='col-'] {
        // Remove margin if the form is using a basic label input.
        &.basic-label-input {
            margin: 0;
        }
        // only put bottom margin on lsq-input when in context of a form.
        margin: 0rem;
        margin-bottom: 1.5rem;
    }

    &:not(.compass-modal-form) {
        &.row,
        .row {
            margin-left: math.div($form-gutter, -2);
            margin-right: math.div($form-gutter, -2);
        }
    }
}
