// Font weights
$font-weights: (
    bolder: bolder,
    bold: bold,
    normal: normal,
    light: light,
    lighter: lighter
);
@each $className, $weight in $font-weights {
    .#{$className} {
        font-weight: $weight;
    }
}

// Font colors
$color-list: (
    'white': $white,
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan
);

.fc {
    @each $name, $color in $color-list {
        &-#{$name} {
            color: $color;
        }
    }
}
