@import 'site-variables';

@each $heading, $size in $font-sizes {
    #{$heading},
    .#{$heading} {
        line-height: map-get($line-heights, $heading);
        margin: 0;
        padding: 0;
        padding-bottom: $headings-margin-bottom;
    }
}
h1,
.h1 {
    font-family: $h1-font-family;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    font-weight: $h1-font-weight;
    padding-bottom: 0.8rem;

    @include media-breakpoint-down(sm) {
        font-size: 3rem;
    }
}
h2,
.h2 {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: $h2-font-weight;
    padding-bottom: 0.5rem;
}
h3,
.h3 {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
}
h4,
.h4 {
    font-family: $h4-font-family;
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
}
h5,
.h5 {
    font-family: $h5-font-family;
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: $h5-font-weight;
}
h6,
.h6 {
    font-family: $h6-font-family;
    font-size: $h6-font-size;
    line-height: $h6-line-height;
    font-weight: $h6-font-weight;
    text-transform: uppercase;
}

// Tags: a
a,
.a {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;

    &.not-bold {
        font-weight: $font-weight-medium !important;
    }

    &:hover {
        color: $green-dark;
    }

    &:not([href]):not([tabindex]) {
        cursor: pointer;
        color: $green;
        text-decoration: underline;

        &.secondary-link {
            color: $gray-darker;
        }
        &:hover {
            color: $green-dark;
            &.secondary-link {
                color: $gray-darkest;
            }
        }
        &:active {
            color: darken($green, 15%);
            &.secondary-link {
                color: darken($gray-darker, 15%);
            }
        }
        &:visited {
            color: $green;
            &.secondary-link {
                color: $gray-darker;
            }
        }
    }

    &.no-styling {
        color: $black;
        text-decoration: none;
        font-weight: 400;
    }
}
a.not-implemented {
    color: $black;
    pointer-events: none;
}
a.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}
.disabled-anchor {
    cursor: not-allowed;
    font-size: 1.2rem;
    color: $green;
    opacity: 0.25;

    &:not(.preserve-case) {
        text-transform: uppercase;
    }
}

.secondary-anchor {
    font-size: 1.2rem;
    cursor: pointer;
    &:not([href]):not([tabindex]) {
        cursor: pointer;
        color: $gray-600;
        &:hover {
            color: $gray-600;
        }
        &:active {
            color: darken($gray-600, 15%);
        }
        &:visited {
            color: $gray-600;
        }
    }
    &:not(.preserve-case) {
        text-transform: uppercase;
    }
}

// Tags: p
p,
.p {
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    line-height: 1.8rem;
    color: $dark-gray;
    margin: 0;

    &.header {
        font-size: 2rem;
        font-weight: $font-weight-medium;
        line-height: 2.4rem;
        color: $black;
    }

    &.label {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 2.4rem;
        color: $black;
    }

    &.body {
        font-size: 1.6rem;
        font-weight: $font-weight-medium;
        line-height: 2.4rem;
        color: $black;
    }

    &.not-bold {
        font-weight: $font-weight-medium !important;
    }
}

.data-label {
    font-size: 1.2rem;
    font-weight: $font-weight-medium;
    line-height: 1.5rem;
    color: $dark-gray;
    letter-spacing: 1px;
    text-transform: uppercase;
}

// Tags: small
small,
.small {
    font-size: 1rem;
}

// Classes: Size
.giant-heading {
    font-size: 8.1rem;
}

// Classes: Color
.text {
    &-dark-gray {
        color: $dark-gray !important;
    }
    &-graphic-gray {
        color: $graphic-gray !important;
    }
    &-mid-gray {
        color: $mid-gray !important;
    }
    &-text-gray {
        color: $text-gray !important;
    }
    &-light-gray {
        color: $light-gray !important;
    }
    &-bright-gray {
        color: $bright-gray !important;
    }
    &-gray-100 {
        color: $gray-100 !important;
    }
    &-gray-200 {
        color: $gray-200 !important;
    }
    &-gray-300 {
        color: $gray-300 !important;
    }
    &-gray-400 {
        color: $gray-400 !important;
    }
    &-gray-500 {
        color: $gray-500 !important;
    }
    &-gray-600 {
        color: $gray-600 !important;
    }
    &-gray-700 {
        color: $gray-700 !important;
    }
    &-gray-800 {
        color: $gray-800 !important;
    }
    &-gray-900 {
        color: $black !important;
    }
    &-black {
        color: $black !important;
    }
    &-green {
        color: $green !important;
    }
    &-green-darker {
        color: $green-darker !important;
    }
    &-blue-highlight {
        color: $blue-highlight !important;
    }
    &-white {
        color: $white !important;
    }
    &-red {
        color: $red !important;
    }
    &-yellow {
        color: $yellow !important;
    }
    &-indigo {
        color: $indigo !important;
    }
    &-slate {
        color: $slate !important;
    }
    &-transparent {
        color: transparent !important;
    }
    &-bold {
        font-weight: bold !important;
    }
}

// Classes: Weight
.fw-demi {
    font-weight: $fw-demi;
}

// Classes: Combined styles
.small-uppercase {
    @include font-family;
    @include bolder;
    @extend .uppercase;
    font-size: 1rem !important;
    line-height: 1.3rem;
    color: $black-lsq;
}

.uppercase {
    text-transform: uppercase;
}

.negative-number {
    font-style: italic;
    color: $dark-gray;
}
