$base-font-size: 62.5%;

body,
html {
    font-size: $base-font-size;
    font-family: $font-family;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    $background: #ffffff;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
}

.container-padding-left {
    @include container-padding-left;
}

.container-padding-right {
    @include container-padding-right;
}

.container-padding {
    @include container-padding-left;
    @include container-padding-right;
}

.container-fluid {
    @include container-padding;

    &.page-container {
        max-width: 172rem;
    }
}
