.navbar {
    height: $navbar-height;
    position: fixed;
    top: 0;
    z-index: $zindex-navigation;
    padding: 0;
    width: 100%;

    .logo {
        position: relative;
        top: -0.2rem;
        max-width: 4rem;
    }

    .navbar-brand {
        &.navigation-hidden {
            cursor: default;
        }

        lsq-icon {
            svg-icon {
                vertical-align: middle;
            }
        }
    }

    #navbar-container-fluid {
        @extend .container-padding;
    }

    .icon-nav-item {
        &.active {
            background: $gray-darker;
        }

        &#account-nav-item {
            padding-right: 0;

            .icon-nav-link {
                padding-right: 0;

                &:hover,
                &[aria-expanded='true'] {
                    .circle-bg {
                        background-color: rgba($gray-darker, 0.7) !important;
                    }
                }
            }

            #account-dropdown-toggle-icon {
                &.circle-bg {
                    transition: background-color 0.15s;
                    display: flex;
                    width: 3.2rem;
                    height: 3.2rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 1.6rem;
                    background-color: $gray-darker;
                }

                lsq-icon {
                    transition: transform 0.3s ease;

                    &.is-rotated {
                        transform: rotate(40deg);
                    }
                }
            }

            .lsq-dropdown-list {
                padding-top: 0;
            }
        }
    }

    .icon-nav-link {
        background-color: transparent !important;
        padding: 0px;

        &.search-nav-link,
        &.search-nav-link-mobile {
            width: 3rem;
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-align: center;
        }
    }

    .pull-right {
        .nav-item {
            border-right: 0.1rem solid $gray-darker;

            &:last-child {
                border-right: none;
            }
        }

        .right-nav-items {
            @include media-breakpoint-up(md) {
                border-right: 0.1rem solid $gray-darker;
            }
        }

        #notification-dropdown-toggle {
            position: relative;

            .notification-badge {
                background-color: $red;
                border-radius: 0.5rem;
                position: absolute;
                height: 1.1rem;
                width: 1.1rem;
                top: 0.5rem;
                right: 0.7rem;
                z-index: $zindex-navigation + 1;
            }
        }

        &.navbar-nav-mobile {
            .nav-item {
                border-right: none;

                &:last-of-type {
                    padding-right: 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &.navbar-nav-mobile {
                display: none;
            }
        }
    }

    &-nav {
        flex-direction: row;

        @include media-breakpoint-up(xl) {
            margin-left: 4.7rem;
        }

        .nav {
            // list item
            &-item {
                position: relative;
                padding: 0 1rem;
                margin: 0;
                line-height: $navbar-height;
                height: $navbar-height;

                &:not(.icon-nav-item) {
                    @include media-breakpoint-down(md) {
                        padding: 0;
                    }
                }

                > * {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 1.5;
                }

                > a.nav-link {
                    // HACK: for some reason the nav links aren't centering perfectly.
                    margin-top: -0.3rem;
                    text-decoration: none;
                }

                .nav-link,
                .nav-link:link,
                .nav-link:focus,
                .nav-text {
                    padding: 1rem;
                    color: $white;
                    border-radius: $border-radius-default;
                    line-height: 1;
                }

                //IE bandaid: IE 11 does not follow all of the rules for flexbox, so this fixes the nav at smaller sizes.
                .nav-link,
                .nav-text {
                    @include media-breakpoint-down(lg) {
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            max-width: 7.75rem;
                        }
                    }
                }

                &.active > a.nav-link,
                &.showing-children > a.nav-link,
                a.nav-link:hover {
                    color: $white;
                    background: $gray-700;
                    max-height: 100%;
                }

                .icon-nav-link::after {
                    display: none;
                }

                .animation-wrapper {
                    overflow: hidden;
                    position: absolute;
                    top: $navbar-height;
                    left: 0;
                }

                .lsq-dropdown {
                    &-container {
                        z-index: 11;
                        min-width: 14rem;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-width: 0;
                        border-top-width: 0.1rem;
                        margin-left: 1rem;

                        // This prevents the mouse from leaving the parent <li>, thereby closing the dropdown
                        &::before {
                            content: ' ';
                            display: block;
                            position: absolute;
                            width: 100%;
                            top: -1.1rem;
                            left: 0;
                            height: 1.1rem;
                        }
                    }

                    &-item {
                        a {
                            text-transform: none;
                            font-weight: $fw-demi !important;
                            font-size: 1.2rem;
                        }
                    }
                }

                &.nav-company-name {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.3rem;
                    line-height: 1;

                    a.nav-link {
                        max-width: 30rem;
                        cursor: default;

                        &:hover {
                            background: transparent;
                        }
                    }
                    .company-name-container {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                    .relationship-name-container {
                        padding-bottom: 1.2rem;
                        margin-top: -2.4rem;

                        span {
                            @extend .small-uppercase;
                            color: $dark-gray;
                        }
                    }
                }
            }

            &-link {
                font-weight: bold;
                font-size: 1.2rem;
                text-transform: uppercase;
                letter-spacing: 0.05rem;
                cursor: pointer;

                &.fc-green {
                    &:hover {
                        color: darken($green, 10%);
                        #plus {
                            fill: darken($green, 10%);
                        }
                    }
                    svg {
                        float: left;
                        position: relative;
                        top: -0.4rem;
                        margin-right: 1rem;
                        fill: $green;
                    }
                    @extend .fc-green;
                }

                &.icon-nav-link svg-icon {
                    padding-left: 0;
                    padding-right: 0;
                }

                &-content {
                    display: flex;
                    align-items: center;

                    > lsq-icon {
                        margin-right: 0.8rem;
                    }
                }
            }

            &-text {
                font-weight: bold;
                font-size: 1.2rem;
                text-transform: uppercase;
                letter-spacing: 0.05rem;
            }
        }

        &.link-is-hovered {
            .nav-item {
                &.active:not(.showing-children) > a.nav-link:not(:hover) {
                    background: transparent !important;
                }
            }
        }

        // Gear dropdown
        &:last-child {
            .animation-wrapper {
                left: auto;
                right: 0;
            }

            .lsq-dropdown-container {
                width: 22.3rem;
            }

            > li:last-child {
                margin-right: 0;

                > .nav-link {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }
        }

        &.navbar-notifications {
            li {
                @extend .full;
                display: inline-block;
                padding: 0 0 0 2.5rem;
                &:first-child {
                    margin: 0;
                }
                a {
                    @extend .full;
                    @include flex-box($row, $around, $center, $nowrap);
                    display: inline-block;
                    span {
                        &.badge {
                            @include prefixed(border-radius, 0.4rem);
                            @include font-family;
                            font-weight: $fw-demi;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                    }
                }
            }
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                width: 0.2rem;
                padding: 0;
                margin: 0;
                background: #373535;
                height: 100%;
                top: 0;
            }
        }
    }
}

.sidebar {
    &-navigation {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: $black-background;
        overflow-x: hidden;
        transition: 0.5s;
        p {
            @extend .text-right;
            padding: 1.5rem;
            margin-bottom: 0;
            width: 100%;
            @include flex-box($row, $end, $end, $nowrap);
            a {
                &.close {
                    svg {
                        .fill {
                            fill: $gray-dark;
                        }
                    }
                }
            }
        }
        a {
            @extend .text-center;
            padding: 0.8rem 0;
            text-decoration: none;
            font-size: 2.5rem;
            color: $gray-dark;
            display: block;
            transition: 0.3s;
            &:hover,
            &:focus {
                color: $white;
            }
        }
    }
}
