$sides: (
    t: top,
    r: right,
    b: bottom,
    l: left
);
$spacing: (
    m: margin,
    p: padding
);

@for $i from 0 through 10 {
    @each $abbr, $side in $sides {
        @each $value, $key in $spacing {
            .#{$value}#{$abbr}-#{$i} {
                #{$key}-#{$side}: #{$i}rem;
            }
        }
    }
    .mtb-#{$i} {
        margin-top: #{$i}rem;
        margin-bottom: #{$i}rem;
    }
    .mlr-#{$i} {
        margin-left: #{$i}rem;
        margin-bottom: #{$i}rem;
    }
    .ptb-#{$i} {
        padding-top: #{$i}rem;
        padding-bottom: #{$i}rem;
    }
    .plr-#{$i} {
        padding-left: #{$i}rem;
        padding-bottom: #{$i}rem;
    }
}
