@import 'styles/_site-variables.scss';

// Overrides for MatDatePicker.
.mat-datepicker-content {
    border: 1px solid $mid-gray;

    .mat-calendar {
        height: auto !important;
        font-family: $font-family !important;

        .mat-button {
            font-family: $font-family !important;
        }

        .mat-calendar-controls {
            margin: 0 0 1rem 0 !important;

            .mat-calendar-period-button {
                font-weight: bold;
                letter-spacing: 0.5px;
            }
        }

        .mat-calendar-table-header {
            color: $graphic-gray;

            .mat-calendar-table-header-divider::after {
                right: 0;
                left: 0;
            }
        }

        .mat-calendar-previous-button,
        .mat-calendar-next-button {
            color: $black;
        }

        .mat-calendar-content {
            padding: 0 1.5rem 1.5rem;
        }

        .mat-calendar-body {
            .mat-calendar-body-label {
                color: $white;
            }

            tr > td[colspan='7'] {
                display: none;
            }
        }

        .mat-calendar-body-cell {
            font-size: 1.4rem;
            font-weight: 600;

            &:not(.mat-calendar-body-disabled):hover {
                .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
                    background-color: $green-light;
                }
            }

            .mat-calendar-body-today:not(.mat-calendar-body-selected) {
                border-color: $green;
            }

            .mat-calendar-body-cell-content {
                color: $dark-gray;

                &.mat-calendar-body-selected {
                    color: $white;
                    background-color: $green;
                }
            }

            &.mat-calendar-body-disabled {
                .mat-calendar-body-cell-content {
                    color: $mid-gray;
                }
            }
        }
    }

    .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background-color: $green-light !important;
    }
}
