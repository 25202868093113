$rowHeight: 3.3rem;

table.expense-popover {
    @extend .full-width;
    background: $transparent;
    tbody tr {
        background: $transparent;
        border-color: $transparent;
        height: $rowHeight;
        @include beforeAndAfter {
            display: none;
        }
        &:first-child {
            td:last-child {
                div {
                    lsq-icon {
                        visibility: hidden;
                    }
                }
            }
        }
        &:nth-last-child(2) {
            td:last-child {
                border-bottom: $border-width-default solid $black-lsq;
            }
        }
        td {
            padding: 0;
            font-size: 1rem;
            height: $rowHeight;
            background-color: $transparent;
            border-color: $transparent;
            vertical-align: middle;
            color: $black-lsq;
            @include beforeAndAfter {
                display: none;
            }

            &:first-child {
                @include bolder;
            }

            &:nth-child(2) {
                text-align: right;
                width: 9.3rem;
                color: $black-lsq;
                font-weight: normal;
                div {
                    @extend .full;
                    @include flex-box($row, $between, $center, $nowrap);
                }
                svg-icon {
                    cursor: default !important;
                }
            }
        }
    }
}
