@mixin input-group($style-placeholder: true, $basic-label-input: false) {
    position: relative;
    font-size: $input-group-font-size;
    line-height: $form-control-height;
    height: $form-control-height;
    border-style: solid;
    border-radius: $border-radius-form-control;
    background-color: $white;

    @if $basic-label-input {
        font-size: $input-group-basic-font-size;
        line-height: $form-control-basic-height;
        height: $form-control-basic-height;
        padding: $form-control-basic-padding;
        border-width: 0.1rem;
    } @else {
        border-width: $border-width-default;
        font-size: $input-group-font-size;
        line-height: $form-control-height;
        height: $form-control-height;
        padding: $form-control-padding;
    }

    &,
    &.border-light {
        border-color: $gray !important;
    }

    &.border-dark {
        border-color: $black-lsq !important;
    }

    &.combined-left {
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
        border-right-width: calc($border-width / 2);
    }
    &.combined-right {
        border-top-left-radius: 0rem;
        border-bottom-left-radius: 0rem;
        border-left-width: calc($border-width / 2);
    }

    &.is-focused {
        border-color: $green !important;

        + label {
            color: $black-lsq;
        }

        &.is-invalid {
            border-color: $red !important;
        }
    }

    &.is-invalid {
        border-color: $red !important;

        + label {
            color: $red;
        }

        .input-group-prepend::after,
        .input-group-append::after {
            background: $red;
            border-color: $red !important;
        }
    }

    &.has-addon-prepended {
        padding-left: 0;

        + label.inner-label {
            left: $input-group-addon-width + 1.65rem;
        }
    }

    > .form-control {
        padding: 0;
        border: none;

        &:-webkit-autofill {
            transition: none;
            -webkit-box-shadow: 0 0 0 30px white inset;
            box-shadow: 0 0 0 30px white inset;
        }

        &:focus {
            outline: none;
            border-color: transparent;
            -webkit-box-shadow: 0 0 0 30px white inset;
            box-shadow: 0 0 0 30px white inset;
        }
    }

    .input-group-prepend {
        display: flex;
        justify-content: center;
        width: $input-group-addon-width;
        height: 2.5rem;
        border-right: 0.1rem solid $gray;
        margin-right: 1.5rem;
        margin-left: 0.1rem;
        margin-top: 0.6rem;

        @if $basic-label-input {
            margin-top: 0.1rem !important;
        }

        lsq-icon {
            display: inline-flex;

            svg-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        lsq-currency-input & {
            lsq-icon {
                position: relative;
                top: -0.1rem;
            }
        }
    }

    .input-group-append {
        @extend .input-group-prepend;
        margin-left: 1.5rem;
        border-right: none;
        border-left: 0.1rem solid $gray;

        @if $basic-label-input {
            margin-right: 0rem;
            margin-top: 0.1rem;
            padding-left: 1rem;
            padding-right: 0rem;
            width: 3rem;
        } @else {
            @extend .input-group-prepend;
            margin-left: 1.5rem;
            margin-right: 0.1rem;
            margin-top: 0.6rem;
        }
    }

    &:not(.is-focused-or-filled) {
        .form-control {
            @include placeholder {
                opacity: 1;
                @include prefixed(transition, all 0.2s);
                font-weight: normal;
                @if $style-placeholder {
                    opacity: 0;
                } @else {
                    opacity: 1;
                    color: $gray-darker;
                }
            }
        }
        & + label {
            font-weight: normal;
            font-size: $form-group-label-font-size;
            color: $gray-darker;
        }
    }

    + label {
        color: $gray-darker;
    }
}
