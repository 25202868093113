$default-border-color: $gray-300;
$border-width: 0.2rem;
$header-padding: 1.8rem 3rem 1rem;
$border-style: solid;

.card {
    @extend .no-border;
    @include card-defaults($white, $default-border-color);

    &-header {
        @include card-header-copy();
        padding: 1.5rem 3rem;
        border-bottom: 0.2rem solid $gray-light;
        h4 {
            @include card-header-copy();
            @extend .no-margin;
            @extend .no-padding;
        }
    }

    &-error {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        padding: 3rem 1rem;
        text-align: center;
        color: $red;
    }

    &.card-inverse {
        @include card-defaults($gray-50, $gray-200);
    }
}
