@import 'mixin-animation';
@import 'mixin-input-group';
@import 'mixin-hacks';
@import 'mixin-cards';
@import 'mixin-container-padding';
@import 'mixin-flexbox';
@import 'mixin-typography';

@mixin box-sizing($sizing: $border-box) {
    -webkit-box-sizing: $sizing;
    -moz-box-sizing: $sizing;
    -o-box-sizing: $sizing;
    box-sizing: $sizing;
}

@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}

@mixin transform($transform) {
    @include prefixed(transform, $transform);
}

@mixin transition($transition...) {
    @include prefixed(transition, $transition);
}

@mixin ie {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        @content;
    }
}

@mixin fill($color: $white, $has-stroke: false, $stroke: $black-lsq) {
    $fill-color: $color !default;
    $stroke-color: $stroke !default;
    .fill {
        fill: $fill-color;
    }
    @if $has-stroke {
        .stroke {
            stroke: $stroke-color;
        }
    }
}

/*
 * Example: @include prefixed(box-shadow, 0 0 1rem 1rem);
 */

@mixin prefixed($property, $value) {
    @if $webkit==true {
        -webkit-#{$property}: #{$value};
    }
    @if $moz==true {
        -moz-#{$property}: #{$value};
    }
    @if $ms==true {
        -ms-#{$property}: #{$value};
    }
    @if $o==true {
        -o-#{$property}: #{$value};
    }
    #{$property}: #{$value};
}

@mixin hover {
    &:hover:not(:disabled):not(.disabled) {
        @content;
    }
}

@mixin active {
    &:active,
    &:focus {
        @content;
    }
}

@mixin horizontal-line($placement, $color: $white, $height: 1rem, $width: 100%) {
    content: '';
    display: block;
    position: absolute;
    width: $width;
    @if $placement == $top {
        top: 0;
    }
    @if $placement == $bottom {
        bottom: 0;
    }
    padding: 0;
    height: $height;
    background: $color;
    @content;
}

@mixin placeholder {
    &::placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin ieOnly {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin suffixed($property) {
    &:#{$property} {
        @content;
    }
}

@mixin beforeAndAfter {
    &::before,
    &::after {
        @content;
    }
}

/// Triangle helper mixin
/// https://css-tricks.com/snippets/sass/css-triangle-mixin/
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentColor] - Triangle color
/// @param {Length} $size [1rem] - Triangle size
@mixin triangle($direction, $color: currentColor, $size: 1rem) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    $opposite-direction: top;

    @if $direction==top {
        $opposite-direction: bottom;
    }

    @if $direction==bottom {
        $opposite-direction: top;
    }

    @if $direction==right {
        $opposite-direction: left;
    }

    @if $direction==left {
        $opposite-direction: right;
    }

    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-#{$opposite-direction}: $size solid $color;
    $perpendicular-borders: $size solid transparent;
    @if $direction==top or $direction==bottom {
        border-left: $perpendicular-borders;
        border-right: $perpendicular-borders;
    } @else if $direction==right or $direction==left {
        border-bottom: $perpendicular-borders;
        border-top: $perpendicular-borders;
    }
}

@mixin settings-section {
    .section {
        &-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 4.2rem;
            margin-bottom: 1rem;
            padding-bottom: 0.3rem;
            border-bottom: 0.2rem solid $gray-line;

            @include media-breakpoint-down(sm) {
                flex-flow: column;
            }
        }
        &-heading {
            font-weight: bold !important;
            line-height: 2.5rem;
            min-height: 2.5rem;
            margin-bottom: 0.5rem;

            &.empty-subheading {
                margin-bottom: 2.2rem;
            }
        }
        &-subheading {
            width: 100%;
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: $fw-demi;
            color: $gray-darker;
            margin-bottom: 0.5rem;
        }
        &-heading-action-btn {
            &.btn-link {
                padding-right: 0 !important;

                @include media-breakpoint-down(sm) {
                    padding-left: 0 !important;
                    padding-top: 1rem !important;
                    padding-bottom: 1rem !important;
                }
            }

            &.button-secondary {
                margin-bottom: 0.7rem;
            }
        }
    }
}
