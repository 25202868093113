@import 'styles/site-variables';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
.min-page-width-small {
    min-width: 768px;
}

.min-page-width-medium {
    min-width: 992px;
}

.min-page-width-large {
    min-width: 1200px;
}

.min-page-width-large-up {
    @include media-breakpoint-up(md) {
        min-width: 1350px;
    }
}

.min-page-width-medium-up {
    @include media-breakpoint-up(md) {
        min-width: 1200px;
    }
}

.card-table-layout {
    .col-xl-4 {
        .card {
            @include media-breakpoint-down(lg) {
                max-width: 75rem;
                margin: auto;
            }

            &-body {
                padding: 3rem;
            }

            &-footer {
                padding: 3rem;
            }

            .row {
                margin-right: 0;
                margin-left: 0;
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }

                .lsq-input,
                .form-group,
                .lsq-textarea {
                    margin: 0;
                }
            }
            .col,
            [class*='col-'] {
                padding: 0;
            }
        }
    }

    .mobile-card {
        .row {
            margin-right: 0;
            margin-left: 0;
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            .lsq-input,
            .form-group,
            .lsq-textarea {
                margin: 0;
            }

            .col,
            [class*='col-'] {
                padding: 0;
            }
        }

        .mobile-footer-button-container {
            position: absolute;
            left: 0;
            bottom: 2rem;
            min-width: 100%;
        }
    }

    .col-xl-8 {
        .card-header {
            padding-left: 0;
        }
        .col-12 {
            padding: 0;
        }
    }

    .mobile-list {
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    lsq-sticky-wrapper .stick-to-top {
        margin-top: 3.5rem;
    }
}
