@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:300|Muli:400,400i,500,600,700&display=swap');

@import 'site-variables';
@import 'ngx-smart-popover/scss/popover-content.component.scss';
@import 'bootstrap/scss/bootstrap.scss';

@import 'angular-material';

@import 'mixins/mixins';
@import 'base';
@import 'utilities';
@import 'layout';
@import 'cursor';
@import 'animations';
@import 'helpers/helpers';
@import 'typography';
@import 'gutters';
@import 'alerts';
@import 'lists';
@import 'paging';
@import 'navbar';
@import 'cards';
@import 'form/form-group';
@import 'form/form';
@import 'form/select';
@import 'shapes';
@import 'search';
@import 'tooltip';
@import 'dropdown';
@import 'popover-templates/popover-templates';
@import 'pages/error-page';
@import 'general';
@import 'examples';

.btn:not(.btn-circle):not(.btn-circle-secondary) {
    border-radius: $border-radius-default;
}

lsq-dropdown,
lsq-select,
lsq-multi-select {
    .lsq-dropdown-container ul li {
        color: $gray-darker;
        font-weight: $fw-demi;

        & > *,
        & > * > * {
            color: $gray-darker;
            font-weight: $fw-demi;

            strong {
                font-weight: bold;
            }
        }

        &:hover,
        &.selected {
            color: $black;

            & > *,
            & > * > * {
                color: $black;
            }
        }
    }
}

lsq-dropdown-navigation {
    .lsq-dropdown-container ul li {
        color: $black;

        & > *,
        & > * > * {
            color: $black;
        }
    }
}

.navbar {
    box-shadow: 0 4px 10px 0 rgba(86, 103, 177, 0.1);
    background: $white;

    &-nav .nav-item {
        .nav-link,
        .nav-link:link,
        .nav-link:focus,
        .nav-text {
            color: $black;
            font-size: 1.4rem;
            font-weight: 600;
            letter-spacing: 0;
            text-transform: none;

            &.text-uppercase {
                font-size: 1.2rem;
                letter-spacing: 1px;
                font-weight: bold;
            }
        }
        &.active > a.nav-link,
        &.showing-children > a.nav-link,
        a.nav-link:hover {
            color: $black;
            background: $off-white;
        }
        lsq-dropdown-navigation .lsq-dropdown-container ul:hover li.active:not(:hover) {
            background: transparent !important;
        }
    }
}

#account-nav-item .lsq-dropdown-item.dropdown-navigation-item-prepended small {
    color: $black !important;
}

.notifications-animation-wrapper {
    padding: 0 10px 10px;
}

.notification-window,
lsq-dropdown .lsq-dropdown-container,
lsq-dropdown-navigation .lsq-dropdown-container,
lsq-select .lsq-dropdown-container,
lsq-multi-select .lsq-dropdown-container {
    box-shadow: 0 4px 10px 0 rgba(86, 103, 177, 0.1);
}
.navbar-nav .nav-item .animation-wrapper {
    margin-right: -1rem;
}
.animation-wrapper > .lsq-dropdown-container {
    margin: 0 1rem 2rem;
}

lsq-dropdown .lsq-dropdown-container,
lsq-dropdown-navigation .lsq-dropdown-container,
lsq-select .lsq-dropdown-container {
    padding-top: 1px;

    &::before {
        display: none;
    }
}

.navbar .pull-right .right-nav-items,
.navbar .pull-right .nav-item {
    @include media-breakpoint-up(md) {
        border-right: 0.1rem solid transparent;
    }
}

#account-nav-item .lsq-dropdown-item.dropdown-navigation-item-prepended {
    border-bottom-color: $gray-100 !important;
    border-radius: 0;
}
#account-nav-item .lsq-dropdown-item.dropdown-navigation-item-appended {
    border-top-color: $gray-100 !important;
    border-radius: 0;
}

lsq-dropdown .lsq-dropdown-container ul li:hover,
lsq-dropdown .lsq-dropdown-container ul li.active,
lsq-dropdown-navigation .lsq-dropdown-container ul li:hover,
lsq-dropdown-navigation .lsq-dropdown-container ul li.active,
lsq-select .lsq-dropdown-container ul li:hover,
lsq-multi-select .lsq-dropdown-container ul li:hover,
lsq-select .lsq-dropdown-container ul li.active,
.lsq-search lsq-dropdown .lsq-dropdown-content .lsq-dropdown-list .lsq-dropdown-item.selected {
    background: $gray-100 !important;
}

lsq-dropdown .lsq-dropdown-container,
lsq-dropdown-navigation .lsq-dropdown-container,
lsq-select .lsq-dropdown-container {
    background: $white !important;
    border: none;
}

.lsq-search lsq-dropdown .lsq-dropdown-content .lsq-dropdown-list .lsq-dropdown-item h5 {
    color: $gray-darker !important;
}

.navbar .icon-nav-item.active {
    background: $gray-100 !important;
}

// We have slightly different structures for input and select components.
form .ng-invalid .lsq-input .form-group .input-group.is-invalid,
form lsq-select.ng-touched.ng-invalid .form-group .input-group,
form lsq-multi-select.ng-touched.ng-invalid .form-group .input-group,
form lsq-textarea.ng-touched.ng-invalid .form-group .input-group {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.form-control {
    color: $black-lsq;
    line-height: 1.1;
}
.input-error-message {
    border-bottom-left-radius: $border-radius-default !important;
    border-bottom-right-radius: $border-radius-default !important;
}
.form-group.has-float-label .input-group + label,
.form-group.has-float-label .lsq-select-viewport + label,
.form-group.has-inner-label .input-group + label,
.form-group.has-inner-label .lsq-select-viewport + label,
.form-group.has-inner-label .input-group.is-focused-or-filled + label {
    color: $gray-darker;
}

lsq-dropdown .lsq-dropdown-container .lsq-select-scroll-container,
lsq-dropdown-navigation .lsq-dropdown-container .lsq-select-scroll-container,
lsq-select .lsq-dropdown-container .lsq-select-scroll-container,
lsq-multi-select .lsq-dropdown-container .lsq-select-scroll-container {
    background: $white;
}

.modal-header h1 {
    margin-bottom: 0;
    padding-bottom: 0.8rem;
}

lsq-date-select .select-group .lsq-date-select-input lsq-dropdown .header .header-inner {
    &:hover button:not(:hover),
    button {
        border-radius: $border-radius-default;
        color: $black !important;
    }

    button.btn-transparent-box.dark:focus,
    button.btn-transparent-box.dark:hover:not(:disabled):not(.disabled),
    button.btn-transparent-box.dark.active,
    button.btn-transparent-box.dark.selected {
        background: $gray-700 !important;
        color: $white !important;
    }
}

calendar .calendar-header h1 {
    font-weight: bold;
}

.select-group .lsq-select-input .lsq-select-options-dropdown {
    background-color: $white;

    .lsq-select-option {
        &.selected,
        &:hover {
            background: $gray-100;

            .lsq-select-option-text {
                color: $black;
            }
        }

        .lsq-select-option-text {
            color: $gray-darker;
        }

        .lsq-select-option-sub-text {
            color: $gray-dark;
        }
    }

    &:hover {
        .lsq-select-option {
            &.selected:not(:hover) {
                background: $gray-200;
            }
        }
    }
}

lsq-dropdown,
lsq-dropdown-navigation,
lsq-select,
lsq-multi-select {
    .lsq-dropdown-container {
        .dropdown-triangle {
            &::before {
                border-radius: 1px;
            }
            &.light::before {
                border: 2px solid $white;
            }
            &.bottom {
                &::before {
                    border-bottom-right-radius: 1px;
                }
            }
        }

        .header {
            background: $gray-50;

            span {
                color: $black;
            }
        }

        &.bg-light {
            ul,
            .lsq-dropdown-content {
                li {
                    &:hover,
                    .active {
                        background: $gray-100;
                    }
                }
            }

            &:not(.has-header) {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.navbar-nav .nav-item .lsq-dropdown-container::before {
    background: transparent;
}

.table tbody > tr > td a {
    font-weight: bold;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: $mid-gray;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
