@mixin font-face($path) {
    @font-face {
        font-family: 'SuisseIntl';
        font-weight: normal;
        src: url('#{$path}suisseintl-regular.woff') format('woff'),
            url('#{$path}suisseintl-regular.woff2') format('woff2');
    }

    @font-face {
        font-family: 'SuisseIntl';
        font-weight: bold;
        src: url('#{$path}suisseintl-bold.woff') format('woff'),
            url('#{$path}suisseintl-bold.woff2') format('woff2');
    }
}
