$image-height: 41.2rem;
$image-width: 68rem;

.error-page {
    &-container {
        width: 100%;
        height: 100%;
        min-height: 55rem;
        display: flex;
        flex-flow: $row $nowrap;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(lg) {
            max-height: 100%;
            min-height: 39rem;
        }
    }
    &-copy {
        width: 30rem;
        height: 20rem;
        margin-top: 13rem;
        margin-right: 1rem;
        margin-left: 35rem;
        h1 {
            margin-bottom: 0.7rem;
        }
        p {
            font-size: 1.3rem;
            margin-bottom: 0;
            line-height: 1.9rem;
        }
        button {
            margin-top: 5.9rem;
        }
        @include media-breakpoint-down(lg) {
            margin: 0;
            text-align: center;
            button {
                margin: 5.9rem auto 0;
            }
        }
    }
    &-image-container {
        display: block;
        width: $image-width;
        height: $image-height;
        background: url('../../images/general/reptar.png') no-repeat;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
