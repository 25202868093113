@mixin font-family($font-stack: $font-family) {
    font-family: $font-stack;
    @content;
}

@mixin bolder {
    letter-spacing: 0.05rem;
    font-weight: 800;
    @content;
}
