$top: $navbar-height;

@for $count from 0 through 10 {
    .top-content-#{$count} {
        .stick-to-top {
            top: #{($count * $announcement-banner-height_value) + $navbar-height_value}rem;
            transition: top 400ms cubic-bezier(0, 0.9, 0.2, 1);

            &.no-navbar {
                top: #{($count * $announcement-banner-height_value)}rem;
            }
        }

        .top-content-height {
            min-height: 90vh;
            position: relative;

            &.no-navbar {
                min-height: calc(100% - (#{($count * $announcement-banner-height_value)}rem));
            }
        }

        &.top-content-search-bar {
            .stick-to-top {
                top: #{($count * $announcement-banner-height_value) +
                    $navbar-height_value +
                    $global-search-height-value}
                    rem;

                @include media-breakpoint-down(sm) {
                    top: #{($count * $announcement-banner-height_value) + $navbar-height_value + $global-search-height-value-mobile}rem;
                }

                &.no-navbar {
                    top: #{($count * $announcement-banner-height_value) +
                        $global-search-height-value}
                        rem;

                    @include media-breakpoint-down(sm) {
                        top: #{($count * $announcement-banner-height_value) + $global-search-height-value-mobile}rem;
                    }
                }
            }

            .top-content-height {
                min-height: calc(
                    100% -
                        (
                            #{($count * $announcement-banner-height_value) +
                                $navbar-height_value +
                                $global-search-height-value}
                                rem
                        )
                );

                @include media-breakpoint-down(md) {
                    min-height: calc(
                        100% -
                            (
                                #{($count * $announcement-banner-height_value) + $navbar-height_value +
                                    $global-search-height-value-mobile} rem
                            )
                    );
                }

                &.no-navbar {
                    min-height: calc(
                        100% -
                            (
                                #{($count * $announcement-banner-height_value) +
                                    $global-search-height-value}
                                    rem
                            )
                    );

                    @include media-breakpoint-down(md) {
                        min-height: calc(
                            100% -
                                (
                                    #{($count * $announcement-banner-height_value) + $global-search-height-value-mobile}rem
                                )
                        );
                    }
                }
            }
        }

        .notification-wrapper {
            max-width: 172rem;
            margin: auto;
            position: relative;
        }

        .global-search-bar {
            top: $navbar-height;
        }

        .lsq-announcement-component {
            top: $navbar-height;

            // This must match the animation in global-search.component.ts.
            transition: top 0.4s cubic-bezier(0, 0.9, 0.2, 1);

            &.global-search-is-expanded {
                top: #{$navbar-height_value + $global-search-height-value}rem;

                @include media-breakpoint-down(md) {
                    top: #{$navbar-height_value + $global-search-height-value-mobile}rem;
                }
            }
        }

        .no-navbar {
            .global-search-bar {
                top: 0;
            }

            .lsq-announcement-component {
                top: 0;

                // This must match the animation in global-search.component.ts.
                transition: top 0.4s cubic-bezier(0, 0.9, 0.2, 1);

                &.global-search-is-expanded {
                    top: #{$global-search-height-value}rem;

                    @include media-breakpoint-down(md) {
                        top: #{$global-search-height-value-mobile}rem;
                    }
                }
            }
        }
    }
}

.wrapper {
    position: absolute;
    right: 0;
    width: 100%;
    padding-bottom: 4rem;

    &.full-screen {
        padding-bottom: 0rem;
    }

    &.bg {
        &-dark {
            background: $black-background;
        }

        &-dashboard {
            background: $dashboard-bg;
        }
    }

    &.has-footer {
        padding-bottom: #{$footer-height_value + 4}rem !important;
    }
}

.min-full-height-content {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100%;
}
