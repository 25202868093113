@mixin container-padding-left {
    @include media-breakpoint-up(lg) {
        padding-left: $container-padding;
    }

    @include media-breakpoint-only(md) {
        padding-left: $container-padding-med;
    }

    @include media-breakpoint-down(sm) {
        padding-left: $container-padding-mobile;
    }
}

@mixin container-padding-right {
    @include media-breakpoint-up(lg) {
        padding-right: $container-padding;
    }

    @include media-breakpoint-only(md) {
        padding-right: $container-padding-med;
    }

    @include media-breakpoint-down(sm) {
        padding-right: $container-padding-mobile;
    }
}

@mixin container-padding {
    @include container-padding-left;
    @include container-padding-right;
}
