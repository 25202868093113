$compact-select-height: 3rem;

.select-group {
    &.sm {
        width: 8.5rem;

        .dropdown-triangle {
            left: 50% !important;
            right: auto !important;
            margin-left: -0.85rem;
        }
    }

    &.lg {
        width: 31.2rem;
    }

    &.has-basic-label {
        &.has-placeholder > .lsq-select-input {
            height: initial;
        }

        .lsq-select-viewport {
            padding: 1rem !important;
        }

        .lsq-select-input {
            .lsq-select-options-dropdown-container {
                &.no-label {
                    top: $form-control-height - 1rem;
                }
                &:not(.no-label) {
                    top: $form-control-height + 1.2rem;
                }

                .lsq-select-options-dropdown .lsq-select-option .lsq-select-option-text {
                    font-weight: normal;
                }
            }
        }
    }

    .lsq-select-input {
        position: relative;

        &.compact {
            height: $compact-select-height;
        }

        .lsq-select-viewport,
        &-compact {
            position: relative;
            color: $black-lsq;
            font-weight: normal;
            cursor: pointer;

            &:not(.border-dark) {
                .chevron-icon.black {
                    @include prefixed(transition, opacity 0.2s);
                    opacity: 0.3;
                }
            }

            .chevron-icon {
                position: absolute;
                right: 1.6rem;
                top: 50%;
                transform: translateY(-50%);
            }

            .lsq-select-viewport-text {
                padding-right: 2rem;
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                user-select: none;
            }
        }

        &-compact {
            height: $compact-select-height;
            line-height: $compact-select-height;
            background-color: $yellow;
            padding-left: 1rem;

            .lsq-select-viewport-text {
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            .triangle {
                position: absolute;
                top: calc(50% - 0.25rem);
                right: 1.5rem;

                &.down {
                    @include triangle('bottom', $black-lsq, 0.5rem);
                }

                &.up {
                    @include triangle('top', $black-lsq, 0.5rem);
                }
            }
        }

        &:focus {
            outline: none !important;

            .form-control {
                border-color: $green !important;
            }

            .lsq-select-viewport {
                &:not(.border-dark) {
                    .chevron-icon.black {
                        opacity: 1;
                    }
                }
            }
        }

        &.is-expanded {
            .lsq-select-viewport {
                .chevron-icon {
                    &:not(.basic-label-icon) {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }

        &.disabled {
            .input-group {
                background-color: $light-gray !important;
                // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
                opacity: 1;
                cursor: not-allowed;
                color: $gray-600;
            }
        }

        .lsq-select-options-dropdown-container {
            position: absolute;
            top: $form-control-height + 1.5rem;
            z-index: $zindex-dropdown;
            width: 100%;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &.compact {
                top: $compact-select-height + 1.5rem;
            }

            &.no-caret {
                top: $form-control-height + 0.5rem;
            }

            &.no-caret.compact {
                top: $compact-select-height + 0.5rem;
            }

            .dropdown-triangle {
                left: auto;
                right: 0.9rem;

                &::before {
                    border: 2px solid $gray-700;
                }
            }
        }

        .lsq-select-options-dropdown {
            padding: 0;
            max-height: 23rem;
            background-color: $black-background;

            .lsq-select-option {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                min-height: 2.6rem;

                &.selected {
                    background: $gray-700;
                    color: $white;
                }

                .lsq-select-option-text {
                    position: relative;
                    font-size: 1.4rem;
                }

                .lsq-select-option-sub-text {
                    position: relative;
                    color: $gray;
                    font-weight: normal;
                    padding: 0 1rem 0 1.4rem;
                }

                .lsq-select-option-null-text {
                    opacity: 0.6;
                }
            }

            &:hover {
                .lsq-select-option {
                    &.selected:not(:hover) {
                        background: transparentize($gray-700, 0.7);
                    }
                }
            }
        }
    }

    &.no-triangle {
        .dropdown-triangle {
            display: none;
        }

        .lsq-select-input {
            .lsq-select-options-dropdown-container {
                top: 4.8rem;
            }
        }
    }

    &.drop-up {
        .lsq-select-input {
            .lsq-select-options-dropdown-container {
                top: auto;
                bottom: $form-control-height + 1.5rem;

                &.compact {
                    top: $compact-select-height + 1.5rem;
                }
            }
        }
    }

    &.bold-label label {
        font-weight: bold !important;
        color: $black-lsq !important;
    }

    label,
    &.has-inner-label label,
    &.has-inner-left-label label,
    &.has-float-label label {
        cursor: pointer !important;
    }

    &.has-inner-left-label {
        label {
            position: relative !important;
            cursor: pointer !important;
            padding-right: 0.9rem;
            line-height: $input-group-line-height + 0.1rem;
            font-weight: bold !important;
            color: $black !important;

            &.text-danger {
                color: $red !important;
            }
        }

        span.lsq-select-viewport-text {
            line-height: $input-group-line-height + 0.1rem;
        }
    }

    &.is-radio-select {
        .lsq-select-input {
            ul li.lsq-select-option {
                margin: 0;
                padding: 0;

                lsq-radio {
                    .radio-container {
                        min-height: 2.6rem;
                    }

                    label {
                        line-height: 1.6rem;
                        position: relative;
                        padding: 0.9rem 2rem 0.9rem 1rem;
                    }
                }
            }
        }
    }
    // Default HTML <select>.
    select {
        &.transparent {
            -webkit-appearance: none;
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }
}
