$paging-font-size: 1.6rem;

.pages {
    &.dropdown-toggle {
        border-left: none;
        border-right: none;
        .dropdown-menu {
            max-height: 20rem;
            overflow-y: scroll;
        }
    }
}

.paging {
    &-row {
        max-height: 5rem;
        margin-top: 2rem;
        > .col {
            @include ieOnly {
                max-height: 5rem;
            }
        }
        &-counts {
            margin-bottom: 0;

            @extend .list-inline;
            @include font-family {
                font-size: $paging-font-size;
                color: $black-lsq;
                line-height: 1.2rem;
            }
            @include ieOnly {
                @include flex-box($row, $start, $center, $nowrap);
            }
            .select {
                lsq-select {
                    .select-group {
                        margin: 0;
                        .lsq-select-input {
                            .lsq-select-viewport {
                                padding: 0 1.5rem;
                                height: 5rem;
                                line-height: 4.7rem;
                                font-size: $paging-font-size;

                                &-text {
                                    position: relative;
                                    top: -0.05rem;
                                }
                            }

                            lsq-dropdown {
                                top: $form-control-height + 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &-totals {
        height: 100%;
        width: 100%;
        @include flex-box($row, $center, $center);
        @include font-family {
            font-size: $paging-font-size;
            color: $black-lsq;
            line-height: 1.2rem;
        }
    }

    &-right {
        @extend .full;
        @include flex-box($row, $end, $center, $nowrap);
        &-wrapper {
            @extend .full-height;
            @include flex-box($row, $between, $center, $nowrap);
            width: 20.5rem;
            .paging-input-group {
                @extend .full-height;
                @include flex-box($row, $between, $center, $nowrap);
                max-width: 15rem;
                .paging-input-wrapper {
                    height: 5rem;
                    .paging-input {
                        width: 7.9rem;
                        -webkit-appearance: none;
                        font-size: $paging-font-size;
                        -moz-appearance: textfield;
                        margin: 0;
                    }
                    .form-group {
                        margin: 0;
                        padding: 0;
                        width: 7.9rem;
                    }
                    input[type='number'] {
                        width: 100%;
                        font-size: $paging-font-size;
                        -webkit-appearance: none !important;
                        -moz-appearance: textfield;
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none !important;
                            margin: 0 !important;
                        }
                    }
                }
                .paging-input-result {
                    display: inline-block;
                    margin-left: 1.5rem;
                }
            }

            .paging-arrow {
                &.next {
                    button {
                        max-width: 2.5rem;
                        padding: 0;
                        margin: 0;
                        span {
                            position: relative;
                            left: 0.1rem;
                        }
                    }
                }

                &.prev {
                    button {
                        max-width: 3rem;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
