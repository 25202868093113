.list-unstyled {
    @include list-unstyled;
}

.list-inline {
    @include list-unstyled;
    margin-left: -5px;
    > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }
}

lsq-select {
    position: relative;
}
