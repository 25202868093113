@import 'mixins/mixin-flexbox';

.full {
    height: 100%;
    width: 100%;
    &-width {
        width: 100%;
    }
    &-height {
        height: 100%;
    }
}

.inline-block,
.ib {
    display: inline-block;
}

.no-border {
    border: none;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.inline-list {
    @extend .list-unstyled;
    @extend .list-inline;
    padding: 0;
    > li {
        padding-left: 0;
        padding-right: 0;
    }
}

.flex {
    &-row {
        &-start {
            &-center {
                @include flex-box($row, $start, $center, $nowrap);
            }
        }
        &-center {
            &-center {
                @include flex-box($row, $center, $center, $nowrap);
            }
        }
        &-end {
            &-center {
                @include flex-box($row, $end, $center, $nowrap);
            }
        }
    }
}

.vertical-align-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@for $i from 1 through 25 {
    .height-#{$i} {
        min-height: #{$i}rem;
    }
    .min-height-#{$i} {
        min-height: #{$i}rem;
    }
    .max-height-#{$i} {
        min-height: #{$i}rem;
    }
}

@each $color, $value in $colors {
    // Background Colors
    .bg-#{$color} {
        background-color: $value !important;
    }

    // Border Colors
    @each $border in $borders {
        .border-#{$color} {
            border-color: $value !important;
        }
    }
}
