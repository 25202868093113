.alert {
    &.alert {
        &-yellow {
            background: $yellow;
            color: $black-lsq;
            font-size: 1.4rem;
            @include font-family;
            font-weight: bold;
        }
    }
}
