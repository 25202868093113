@import 'styles/site-variables';

$tour-zindex: $zindex-modal + 1;
$tour-step-color: $black-lsq;
$tour-text-color: $white;
$tour-next-button-color: $green;
$tour-next-button-hover: $green-hover;
$tour-back-button-color: $green;
$tour-next-text-color: $white;
$tour-next-text-hover: $white;
$tour-skip-link-color: #5e5e5e; // custom color
$tour-orb-color: $indigo;
$tour-shadow-color: $gray-darker;
