$cursors: (
    alias: alias,
    all-scroll: all-scroll,
    auto: auto,
    cell: cell,
    context-menu: context-menu,
    col-resize: col-resize,
    copy: copy,
    crosshair: crosshair,
    default: default,
    e-resize: e-resize,
    ew-resize: ew-resize,
    grab: grab,
    grabbing: grabbing,
    help: help,
    move: move,
    n-resize: n-resize,
    ne-resize: ne-resize,
    nesw-resize: nesw-resize,
    ns-resize: ns-resize,
    nw-resize: nw-resize,
    nwse-resize: nwse-resize,
    no-drop: no-drop,
    none: none,
    not-allowed: not-allowed,
    pointer: pointer,
    progress: progress,
    row-resize: row-resize,
    s-resize: s-resize,
    se-resize: se-resize,
    sw-resize: sw-resize,
    text: text,
    w-resize: w-resize,
    wait: wait,
    zoom-in: zoom-in,
    zoom-out: zoom-out
);

.cursor {
    @each $key, $value in $cursors {
        &-#{$key} {
            cursor: $value;
        }
    }
}
