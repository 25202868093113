@font-face {
  font-family: "SuisseIntl";
  font-weight: normal;
  src: url("../../assets/fonts/suisseintl-regular.woff") format("woff"), url("../../assets/fonts/suisseintl-regular.woff2") format("woff2");
}
@font-face {
  font-family: "SuisseIntl";
  font-weight: bold;
  src: url("../../assets/fonts/suisseintl-bold.woff") format("woff"), url("../../assets/fonts/suisseintl-bold.woff2") format("woff2");
}
.logo {
  max-width: 5.4rem;
}

.nav-link.fc-green {
  color: #008661 !important;
}

.aging-report-datepicker {
  margin-right: 9rem;
}

.min-page-width-small {
  min-width: 768px;
}

.min-page-width-medium {
  min-width: 992px;
}

.min-page-width-large {
  min-width: 1200px;
}

@media (min-width: 768px) {
  .min-page-width-large-up {
    min-width: 1350px;
  }
}

@media (min-width: 768px) {
  .min-page-width-medium-up {
    min-width: 1200px;
  }
}

@media (max-width: 1199.98px) {
  .card-table-layout .col-xl-4 .card {
    max-width: 75rem;
    margin: auto;
  }
}
.card-table-layout .col-xl-4 .card-body {
  padding: 3rem;
}
.card-table-layout .col-xl-4 .card-footer {
  padding: 3rem;
}
.card-table-layout .col-xl-4 .card .row {
  margin-right: 0;
  margin-left: 0;
}
.card-table-layout .col-xl-4 .card .row:not(:last-child) {
  margin-bottom: 1.5rem;
}
.card-table-layout .col-xl-4 .card .row .lsq-input,
.card-table-layout .col-xl-4 .card .row .form-group,
.card-table-layout .col-xl-4 .card .row .lsq-textarea {
  margin: 0;
}
.card-table-layout .col-xl-4 .card .col,
.card-table-layout .col-xl-4 .card [class*=col-] {
  padding: 0;
}
.card-table-layout .mobile-card .row {
  margin-right: 0;
  margin-left: 0;
}
.card-table-layout .mobile-card .row:not(:last-child) {
  margin-bottom: 1.5rem;
}
.card-table-layout .mobile-card .row .lsq-input,
.card-table-layout .mobile-card .row .form-group,
.card-table-layout .mobile-card .row .lsq-textarea {
  margin: 0;
}
.card-table-layout .mobile-card .row .col,
.card-table-layout .mobile-card .row [class*=col-] {
  padding: 0;
}
.card-table-layout .mobile-card .mobile-footer-button-container {
  position: absolute;
  left: 0;
  bottom: 2rem;
  min-width: 100%;
}
.card-table-layout .col-xl-8 .card-header {
  padding-left: 0;
}
.card-table-layout .col-xl-8 .col-12 {
  padding: 0;
}
.card-table-layout .mobile-list .card-body {
  padding-left: 0;
  padding-right: 0;
}
.card-table-layout lsq-sticky-wrapper .stick-to-top {
  margin-top: 3.5rem;
}

.counselLink-brand-theme lsq-select .select-group .lsq-select-input:focus form-control {
  border-color: #77a5d1;
}
.counselLink-brand-theme .form-group.has-float-label .input-group.is-focused,
.counselLink-brand-theme .form-group.has-inner-label .input-group.is-focused,
.counselLink-brand-theme .lsq-textarea .form-group .input-group.is-focused {
  border-color: #77a5d1 !important;
}
.counselLink-brand-theme button.button-primary {
  background: #e8171f !important;
  border: 1px solid #e8171f !important;
}
.counselLink-brand-theme button.button-primary:hover {
  background: #ed454c !important;
}
.counselLink-brand-theme button.button-primary:disabled {
  background: #ed454c !important;
  color: #ffffff !important;
}
.counselLink-brand-theme button.button-primary:focus:not(:hover), .counselLink-brand-theme button.button-primary:active:not(:hover), .counselLink-brand-theme button.button-primary.selected:not(:hover) {
  background-color: #e8171f !important;
  border-color: #e8171f !important;
}
.counselLink-brand-theme button.button-primary lsq-button-spinner .mat-progress-spinner circle {
  stroke: #ffffff !important;
}
.counselLink-brand-theme button.button-transparent {
  color: #e8171f !important;
}
.counselLink-brand-theme button.button-transparent:hover {
  color: #ed454c !important;
}
.counselLink-brand-theme a:hover {
  color: #000000 !important;
}
.counselLink-brand-theme lsq-radio lsq-icon svg-icon {
  color: #77a5d1 !important;
}
.counselLink-brand-theme .info-section-wrapper {
  background-color: #414d57 !important;
}
.counselLink-brand-theme lsq-checkbox .checkbox-icon.checked {
  background-color: #e8171f !important;
  border-color: #e8171f !important;
}

body.tour-open {
  overflow: hidden;
}

ngx-guided-tour .guided-tour-user-input-mask {
  z-index: 1081;
}
ngx-guided-tour .guided-tour-spotlight-overlay {
  z-index: 1082;
}
ngx-guided-tour .tour-orb {
  z-index: 1079;
  background-color: #5667b1;
  box-shadow: 0 0 0.3rem 0.1rem #5667b1;
}
ngx-guided-tour .tour-orb .tour-orb-ring::after {
  border: 1rem solid #5667b1;
  box-shadow: 0 0 0.1rem 0.1rem #5667b1;
}
ngx-guided-tour .tour-step {
  z-index: 1083;
}
ngx-guided-tour .tour-step.tour-bottom .tour-arrow::before, ngx-guided-tour .tour-step.tour-bottom-right .tour-arrow::before, ngx-guided-tour .tour-step.tour-bottom-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-bottom: 1rem solid #000000;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-top .tour-arrow::before, ngx-guided-tour .tour-step.tour-top-right .tour-arrow::before, ngx-guided-tour .tour-step.tour-top-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-top: 1rem solid #000000;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-left: 1rem solid #000000;
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-right .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-right: 1rem solid #000000;
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
}
ngx-guided-tour .tour-step .tour-block {
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0 0.4rem 0.6rem #4d5863;
}
ngx-guided-tour .tour-step .tour-buttons button.skip-button {
  color: #5e5e5e;
}
ngx-guided-tour .tour-step .tour-buttons .back-button {
  color: #008661;
}
ngx-guided-tour .tour-step .tour-buttons .next-button {
  background-color: #008661;
  color: #ffffff;
}
ngx-guided-tour .tour-step .tour-buttons .next-button:hover {
  background-color: #00533c;
  color: #ffffff;
}