@use "sass:math";
$triangle-border-radius: $border-radius-default;

// TODO: Refactor these components to allow for component-level stylesheets. E.g. Make it so
// lsq-dropdown-navigation and lsq-select wrap lsq-dropdown, and move these styles to
// lsq-dropdown.component.scss.
lsq-dropdown,
lsq-dropdown-navigation,
lsq-select {
    .lsq-dropdown-container {
        position: relative;
        background: $black-background !important;
        border: $border-width-default solid $gray-700;
        box-shadow: 0 -0.1rem 0.9rem 0.3rem rgba(0, 0, 0, 0.23);
        width: 100%;
        min-width: 7rem;

        .lsq-select-scroll-container {
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            background-color: $black-background;
            position: relative;
            z-index: $zindex-dropdown;
        }

        &.has-header {
            padding-top: 0;
        }

        .dropdown-triangle {
            z-index: 9;
            left: 0.9rem;
            top: -$triangle-peek;

            &::before {
                background: $black-background;
                border: 2px solid $gray-700;
            }

            &.light::before {
                background: $white;
            }

            &.left {
                left: 1rem;
                right: auto;
            }

            &.right {
                right: 1rem;
                left: auto;
            }

            &.center {
                left: 50%;
                margin-left: -(math.div($triangle-hypotenuse, 2));
            }

            &.bottom {
                top: auto;
                bottom: -$triangle-peek;

                &::before {
                    top: -(math.div($triangle-hypotenuse, 2)) + $triangle-offset-bottom;
                    border-bottom-right-radius: $triangle-border-radius;
                }
            }
        }

        .header {
            position: relative;
            z-index: 11;
            background: $black-background;
            line-height: 3.8rem;
            min-height: 3.8rem;
            padding: 0;
            margin: 0;

            &-inner {
                box-shadow: 0px 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
                padding-right: 1rem;
                padding-left: 1rem;
            }

            span {
                color: $white;
                font-weight: 600;
                line-height: 1.4;
                font-size: 1.1rem;
            }
        }

        ul,
        .lsq-dropdown-content {
            position: relative;
            z-index: $zindex-dropdown;
            margin-bottom: 0;
            padding: 1rem 2rem;
        }

        .ng-scrollbar-container {
            z-index: $zindex-dropdown;
        }

        ul {
            padding-right: 0;
            padding-left: 0;

            li {
                cursor: pointer;
                margin: 0 1rem;
                padding: 0;
                font-size: 1.1rem;
                font-weight: 600;

                // Ensure that we don't reach into deeply-nested markup like `svg`s.
                > *,
                > * > * {
                    cursor: pointer !important;
                    color: $white;
                }

                &:hover,
                &.active {
                    background: $gray-700;
                }

                > span,
                > a,
                > label {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 1rem 0 1.4rem;
                    width: 100%;
                    line-height: 1.5;
                    color: $white;
                    font-weight: 600;
                }

                &.lsq-dropdown-navigation-item {
                    > a {
                        line-height: 3.4rem;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 1.5;
                        }
                    }

                    .is-child {
                        padding-left: 1rem;
                    }

                    .is-grandchild {
                        padding-left: 2rem;
                    }
                }
            }

            &:hover {
                li.active:not(:hover) {
                    background: transparent;
                }
            }
        }

        &.bg-light {
            background: $white !important;
            border: none;
            ul,
            .lsq-dropdown-content {
                li {
                    > span,
                    > label {
                        color: $black-lsq;
                    }

                    &:hover,
                    .active {
                        background: $gray-300;
                    }
                }
            }

            &:not(.has-header) {
                &::before {
                    content: ' ';
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 1.2rem;
                    background: $black-background;
                    border: $border-width-default solid $gray-700;
                    border-bottom: none;
                    border-radius: $border-radius-default;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .lsq-dropdown-footer {
        @extend .row;
        margin-right: -2rem;
        margin-left: -2rem;
        padding-top: 1rem;
        padding-bottom: 0;
        line-height: 6rem;
        border-top: $border-width-default solid $gray-300;
        margin-top: 2.1rem;
        clear: both;

        .col-6 {
            padding-right: 2rem;
            text-align: right;
        }
        .col-6:first-child {
            padding-left: 2rem;
            text-align: left;
        }
    }
}

lsq-dropdown {
    position: absolute;
    left: 0;
    z-index: 11;
    width: 100%;
    min-width: 19rem;
}

lsq-dropdown-navigation {
    .lsq-dropdown-container {
        width: 22rem;
    }
}
