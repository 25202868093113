svg {
    &#arrow-right-long {
        @include fill();
    }
    &#arrow-right-short {
        @include fill($black-lsq);
    }
    &#button-arrow {
        @include fill($black-lsq);
    }
    &#checked-box {
        @include fill($green);
    }
    &#checkmark {
        @include fill($green);
    }
    &#down-arrow {
        @include fill();
    }
    &#exit {
        @include fill();
    }
    &#logo {
        @include fill();
    }
    &#plus {
        fill: $white;
    }
    &#scale {
        @include fill($green);
    }
    &#white-button-arrow {
        @include fill();
    }
}
