@use "sass:math";

$sm-width: 8rem;
$lg-width: 20rem;
$triangle-distance-from-edge: 1rem;
$distance-from-button: 1.4rem;
$content-offset: 0.8rem;

*[lsqhastooltip],
*[lsqHasTooltip] {
    cursor: default;

    & + lsq-tooltip {
        opacity: 1;
        width: auto;
        height: auto;
        overflow: visible;
    }

    &:not([aria-expanded='true']) + lsq-tooltip {
        opacity: 0;
    }
    &:not(.fading-out):not([aria-expanded='true']) + lsq-tooltip {
        overflow: hidden;
        height: 0;
        width: 0;
        left: -9999rem;
        top: -9999rem;
    }
}

lsq-tooltip {
    position: absolute;
    z-index: 11;
    transition: opacity 0.2s;
}

.lsq-tooltip-main {
    position: absolute;
    z-index: 10;
    background: $gray-600;
    color: $white;
    width: $sm-width;
    margin-left: -(math.div($sm-width, 2));
    box-shadow: 0 0.2rem 1rem 0 rgba(98, 90, 255, 0.15);
    border-radius: 0.3rem;

    &.top {
        bottom: $distance-from-button;

        .tooltip-triangle {
            left: 50%;
            bottom: -$triangle-peek;
            top: auto;
            right: auto;
            margin-left: -(math.div($triangle-hypotenuse, 2));

            &::before {
                border-bottom-right-radius: $triangle-border-radius;
                top: -(math.div($triangle-hypotenuse, 2)) + $triangle-offset-top;
            }
        }
    }

    &.bottom {
        top: $distance-from-button + 2rem;

        .tooltip-triangle {
            left: 50%;
            right: auto;
            top: -$triangle-peek;
            margin-left: -(math.div($triangle-hypotenuse, 2));

            &::before {
                border-top-left-radius: $triangle-border-radius;
            }
        }
    }

    .tooltip-triangle {
        z-index: 11;

        &::before {
            background: $gray-600;
        }
    }

    .lsq-tooltip-content {
        position: relative;
        z-index: 12;
        padding: 1.3rem 1.5rem 1.3rem;
        word-wrap: normal;

        p {
            font-weight: bold;
            font-size: 1.1rem;
            line-height: 1.4rem;
            text-align: center;
            margin-bottom: 0;
        }
    }
}
