@use "sass:math";

span[class*='triangle'] {
    display: block;
    position: absolute;
    width: $triangle-hypotenuse;
    height: math.div($triangle-hypotenuse, 2);
    overflow: hidden;
}

span[class*='triangle']::before {
    content: ' ';
    display: block;
    position: absolute;
    top: $triangle-offset-top;
    left: $triangle-offset-left;
    width: $triangle-leg;
    height: $triangle-leg;
    transform: rotate(45deg);
    border-top-left-radius: $triangle-border-radius;
}
