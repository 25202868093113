.story-wrapper {
    min-width: 100%;
    height: 100%;
    padding: 2rem;

    .story-header {
        width: 100%;
        border-bottom: 1px solid $gray-700;
    }

    .examples-wrapper {
        padding-top: 2rem;
        display: flex;
        flex-wrap: wrap;

        .examples {
            min-width: 30rem;
            margin-right: 4rem;
            margin-bottom: 5rem;

            .examples-title {
                margin-bottom: 1rem;
            }

            .example {
                min-width: 30rem;
                margin-bottom: 2rem;

                .example-label {
                    font-size: 1.4rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
