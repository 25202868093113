@mixin card-header-copy() {
    @include font-family {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.6rem;
        color: $black-lsq;
    }
}

@mixin card-defaults($bg-color, $border-color, $border-width: 0.2rem, $border-style: solid) {
    background: $bg-color;
    .card {
        &-header {
            background: $bg-color;
            border-bottom: $border-width $border-style $border-color;
        }
        &-body {
            background: $bg-color;
        }
        &-footer {
            border-top: $border-width $border-style $border-color;
            background: $bg-color;
        }
    }
}
