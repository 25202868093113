.search-form {
    height: 100%;
    width: 50%;
    margin-right: 5rem;
    @include flex-box($row, $end, $center, $nowrap);
    &-addon {
        &-left,
        &-right {
            width: 5rem;
            border-bottom: 0.1rem solid $green;
            height: 4rem;
        }
        &-left {
            @include flex-box($row, $start, $center, $nowrap);
        }

        &-right {
            @include flex-box($row, $end, $center, $nowrap);
            cursor: pointer;
        }
    }

    &-input {
        width: 45rem;
        height: 4rem;
        border: none;
        background: transparent;
        border-bottom: 0.1rem solid $green;
        @include placeholder {
            @include font-family;
            font-size: 1.2rem;
            color: $gray-600;
        }
        &:focus {
            outline: none;
        }
    }
}

.search {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba($white, 0.6);
    z-index: 1001;
    &-container {
        width: 100%;
        @include flex-box($row, $end, $center, $nowrap);
        height: 8.7rem;
    }
    &.open {
        display: block;
        @include prefixed(animation, fadeIn 1s linear forwards);
        opacity: 1;
    }
    &.closing {
        display: block;
        animation-direction: reverse;
        @include prefixed(animation, fadeOut 0.5s linear forwards);
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
